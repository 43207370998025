.greencolor{
    color: #128983;
}

.pinkcolor{
    color: #F62088;
}

.bluecolor{
    color: #0000FF;
}

.bagde-green{
    color: #128983 !important;
    background-color: #DCEEED !important;
}

.bagde-pink{
    color: #F62088 !important;
    background-color: #FEDEED !important;
}

.bagde-blue{
    color: #0000FF !important;
    background-color: #D9D9FF !important;
}

.line{
    margin: 20px 0px;
    width: 100%;
    height: 1px;
    background-color: var(--sky-blue);
}

.border-green{
    border-color: #C3E1E0;
}

.nameCircleinner{
    width: 35px;
    height: 35px;
    color: #000;
    background-color: #C3E1E0 !important;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
}

.nameCircle{
    border: 2px solid #C3E1E0;
    border-radius: 50%;
    padding: 5px;
}

.clickable{
    cursor: pointer;
}